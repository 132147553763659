<template>
  <div class="login content-centered">
    <div class="login__wrap">
      <form action="#" method="post" class="login__form">
        <h2 class="login__title">Вход в аккаунт</h2>
        <label class="label-wrap login__form-control">
          <span class="label">Почта</span>
          <input v-model="loginForm.email" type="email" class="input input--w100" placeholder="mail@gmail.com" />
          <FormErrorListPrinter :errorList="loginFormErrors.email" />
        </label>
        <label class="label-wrap login__form-control">
          <span class="label">
            <span>Пароль</span>
            <router-link to="/forgot" class="login__forgot">Забыли пароль?</router-link>
          </span>
          <span class="input-wrap">
            <input
              v-model="loginForm.password"
              type="password"
              class="input input--w100"
              placeholder="********************"
            />
            <span class="show-password" @click="showPassword">
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 6C10 7.21562 9.10457 8.20107 8 8.20107C6.89543 8.20107 6 7.21562 6 6C6 4.78438 6.89543 3.79893 8 3.79893C9.10457 3.79893 10 4.78438 10 6Z"
                  fill="white"
                  fill-opacity="0.25"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.97333 1.82657C1.82927 2.74886 0.830817 4.01571 0.0843772 5.61686C0.0288875 5.73583 0 5.867 0 6C0 6.133 0.0288875 6.26417 0.0843772 6.38314C1.81087 10.0834 4.87663 12 8 12C9.24407 12 10.4801 11.6957 11.6242 11.0957C12.8449 10.3294 13.4544 9.84242 14.3755 8.83886C14.9877 8.09661 15.5056 7.27088 15.9156 6.38314C15.9711 6.26417 16 6.133 16 6C16 5.867 15.9711 5.73583 15.9156 5.61686C14.1891 1.91657 11.1226 0 8 0C7.42969 0.000106036 6.86096 0.0641696 6.30311 0.191143C4.87186 0.541445 4.1409 0.89371 2.97333 1.82657ZM8 10.2857C8.81124 10.2857 9.62729 10.1237 10.4113 9.79543C11.731 9.12218 12.388 8.67318 13.2419 7.62514C13.6283 7.14857 13.9827 6.60771 14.2963 6C12.8019 3.10286 10.3761 1.71429 8 1.71429C7.9104 1.71429 7.82079 1.716 7.73119 1.72029C6.1827 1.84512 5.39548 2.1583 4.11819 3.054C3.19014 3.75429 2.3573 4.73229 1.70366 6C3.19814 8.89714 5.62467 10.2857 8 10.2857Z"
                  fill="white"
                  fill-opacity="0.25"
                />
              </svg>
            </span>
          </span>
          <FormErrorListPrinter :errorList="loginFormErrors.password" />
        </label>
        <button @click="login" class="button login__submit" type="button">Войти</button>
        <div class="login__register" v-if="allowRegister">
          Нет аккаунта? <router-link :to="{ name: 'register' }">Зарегистрироватся</router-link>
        </div>
      </form>
      <div class="login__logo">
        <img src="img/logo.png" alt="Big Boss Reddit" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormErrorListPrinter from "../components/FormErrorListPrinter";

const loginFormDefault = {
  email: null,
  password: null,
};

export default {
  name: "Login",
  metaInfo: {
    title: "BIG BOSS REDDIT | Login",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },
    ],
    link: [{ rel: "favicon", href: "public/favicon.ico" }],
  },
  components: {
    FormErrorListPrinter,
  },
  data() {
    return {
      loginForm: { ...loginFormDefault },
      loginFormErrors: { ...loginFormDefault },
      allowRegister: +process.env.VUE_APP_ALLOW_REGISTER,
    };
  },
  methods: {
    ...mapActions("RedditStore", {
      loginAction: "LOGIN",
    }),
    async login() {
      let loader = this.$loading.show();
      this.loginFormErrors = { ...this.loginFormDefault };
      try {
        let { token, abilities } = await this.loginAction(this.loginForm);
        localStorage.setItem("token", token);
        localStorage.setItem("abilities", abilities);
        this.axios.defaults.headers = { Authorization: `Bearer ${token}`, Accept: "application/json" };
        this.$toast.success("Успех");
        await this.$router.push({ name: "index" });
      } catch (e) {
        this.loginFormErrors = e.response.data.errors;
        this.$toast.error("Ошибка авторизации");
      } finally {
        loader.hide();
      }
    },
    showPassword(e) {
      let input = e.target.closest(".input-wrap").querySelector("input");

      if (input.getAttribute("type") === "password") {
        input.setAttribute("type", "text");
      } else {
        input.setAttribute("type", "password");
      }
    },
  },
};
</script>

<style>
body {
  background-image: url("../../public/img/login-background.png");
  height: 100vh !important;
}
</style>
